import React from "react";
import ReactMarkdown from "react-markdown";

export const HTMLContent = ({ content, className }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
)

export const MdContent = ({ content, className }) => (
  <div className={className}><ReactMarkdown>{content}</ReactMarkdown></div>
)

const Content = ({ content, className }) => (
  <div className={className}>{content}</div>
)

export default Content;